<template>
  <div class="qiqb-table">
    <div class="qiqb-table-item">
      <div class="table-operator">
        <div class="table-operator-title">{{ name }}</div>
        <a-button type="primary" @click="addmodal">添加</a-button>
      </div>
      <div class="table-item">
        <a-table :columns="columns" :data-source="list" :pagination="page" size="middle" @change="more">
          <template slot="img" slot-scope="text">
            <span>
              <img :src="text.fileUrl" style="width: 300px;" />
            </span>
          </template>

          <span slot="action" slot-scope="text, record" style="align-items: center;">
            <a @click="handleEdit(record)">编辑</a>
            <a-divider type="vertical" v-if="record.pid != 0" />
            <a-popconfirm title="确认删除？" @confirm="del(record)" v-if="record.pid != 0">
              <a-icon slot="icon" type="question-circle-o" style="color: red" />
              <a>删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </div>
    </div>

    <!-- 提交表单 -->
    <!-- <create-form ref="createModal" @ok="handleOk" :form="form"/> -->
    <a-modal :title="'报告模板'" :width="640" :visible="show" @ok="edit" @cancel="show = false">
      <a-form :form="form"
        v-bind="{ labelCol: { xs: { span: 24 }, sm: { span: 7 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 13 } } }">
        <a-form-item label="隐藏字段" style="display:none;">
        </a-form-item>
        <a-form-item label="模板图片">
          <Img ref="img" @ok="img_ok" :fileUrl="form.fileUrl"></Img>
          <!-- <a-input type="hidden" v-decorator="[ 'img', { initialValue:0, rules: [{ required: true, message: '' }] }]" /> -->
        </a-form-item>
        <a-form-item label="模板名称">
          <a-input v-model="form.name" />
        </a-form-item>
        <a-form-item label="排序">
          <a-input v-model="form.sort" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal :title="'报告模板'" :width="640" :visible="addshow" @ok="add" @cancel="addshow = false">
      <a-form :form="form"
        v-bind="{ labelCol: { xs: { span: 24 }, sm: { span: 7 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 13 } } }">
        <a-form-item label="隐藏字段" style="display:none;">
        </a-form-item>
        <a-form-item label="模板图片">
          <Img ref="img" @ok="img_ok1" :fileUrl="form1.fileUrl"></Img>
          <!-- <a-input type="hidden" v-decorator="[ 'img', { initialValue:0, rules: [{ required: true, message: '' }] }]" /> -->
        </a-form-item>
        <a-form-item label="模板名称">
          <a-input v-model="form1.name" />
        </a-form-item>
        <a-form-item label="排序">
          <a-input v-model="form1.sort" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// import CreateForm from "./modules/CreateForm";
import Img from "../../../components/Img.vue";
import { modal_list, update_modal, deleteMoban, addMoban } from "../../../api/index.js";

export default {
  components: {
    // CreateForm,
    Img
  },
  methods: {
    async more(e) {
      let res = await modal_list();
      this.list = res.data
    },
    handleEdit(record) {
      this.form = record;
      this.show = true
      // this.$refs.createModal.init(record);
    },
    async edit(e) {
      let res = await update_modal(this.form)
      if (res.status == 200) {
        this.form = {
          name: '',
          sort: '',
          fileUrl: ''
        }
        this.more()
        this.show = false
      }

    },
    del(e) {
      deleteMoban({ id: e.id }).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.detail);
          this.more()
        } else {
          this.$message.error(res.detail);
        }
      })
    },
    addmodal() {
      this.addshow = true
    },
    img_ok1(e) {
      console.log(e);
      this.form1.fileUrl = e
    },
    img_ok(e) {
      console.log(e);
      this.form.fileUrl = e
    },
    async add() {
      let res = await addMoban(this.form1)
      if (res.status == 200) {
        this.form1 = {
          name: '',
          sort: '',
          fileUrl: ''
        }
        this.more()
        this.addshow = false
      }
    },
  },
  mounted() {
    this.more({ current: 1 });
  },
  data() {
    return {
      name: "",
      addshow: false,
      id: 0,
      list: [],
      queryParam: {},
      page: { pageSize: "10", total: 0, current: 0 },
      columns: [
        { key: "id", title: "编号", dataIndex: "code", width: 200 },
        { title: "封面图片", key: "img", scopedSlots: { customRender: "img" } },
        { title: "名称", dataIndex: "name", width: 300 },
        { title: "排序", dataIndex: "sort", width: 200 },
        {
          title: "操作",
          key: "action",
          width: 150,
          scopedSlots: { customRender: "action" },
        },
      ],
      item: [],
      form: {
        id: '',
        name: '',
        sort: '',
        fileUrl: ''
      },
      form1: {
        name: '',
        sort: '',
        fileUrl: ''
      },
      show: false
    };
  },
  props: {
    msg: String,
  },
  watch: {
    $route: {
      handler: function (route) {
        this.name = route.name;
      },
      immediate: true,
    },
  },
};
</script>